@tailwind base;
@tailwind components;
@tailwind utilities;


  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Poppins:wght@300&display=swap');


  body{
    font-family: 'Montserrat', sans-serif;
font-family: 'Poppins', sans-serif;
  }

  .customBlue{
    color: #1bafd0;
  }

  .customRed{
    color: #fd636b;
  }

  .customYellow{
    color: #ffb900;
  }

  .customPurple{
   color: #6967ce;
  }

  .customGreen{
  color: #3be8b0;
  }

  .customGray{
   color: #676767;
  }




  .blur-background {
    filter: blur(5px); /* Adjust the blur intensity as needed */
    transition: filter 0.3s ease-in-out; /* Add a smooth transition effect */
  }
